import React, {useEffect} from 'react';
import {Layout} from "../components/Layout";
import {BlogPostHeader} from "../components/BlogPostHeader";
import {PageMainColumnWrapper} from "../components/PageMainColumnWrapper";
import * as styles from './blogPost.module.scss';
import {BlogPostContent} from "../components/BlogPostContent";
import {graphql} from "gatsby";
import {createSeoData} from "../functions/createSeoData";
import {initSpreaker} from "../functions/initSpreaker";
import {ArticleJsonLd} from 'gatsby-plugin-next-seo';
import {useCurrentUrl} from "../hooks/useCurrentUrl";
import {useUrlToPath} from "../hooks/useUrlToPath";
import {ServiceBanner} from "../components/ServiceBanner";
import {dateTimeToDate} from "../functions/dateTimeToDate";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {useIntl} from "gatsby-plugin-react-intl";

export const query = graphql`fragment Seo on STRAPI__COMPONENT_UTILS_SEO_DATA {
    title
    author {
        name
    }
    image {
        localFile {
            childImageSharp {
                gatsbyImageData(width: 1200, height: 627, placeholder: NONE, layout: FIXED)
            }
        }
    }
    description
    keywords
    publishDate(formatString: "YYYY-MM-DD")
    modifiedDate(formatString: "YYYY-MM-DD")
}

query BlogPost($locale: String!, $lang: String!, $slug: String!, $serviceBannersSlugs: [String!], $hasServiceBanners: Boolean!) {
    post: strapiBlogPost(slug: {eq: $slug}) {
        title
        intro {
            data {
                childMarkdownRemark {
                    excerpt
                    html
                }
            }
        }
        createdAt
        publishDate(formatString: "YYYY-MM-DD")
        updatedAt
        content {
            data {
                childMarkdownRemark {
                    html
                }    
            }
        }
        image {
            localFile {
                url
                childImageSharp {
                    gatsbyImageData(width: 800, placeholder: NONE, layout: FIXED)
                }
            }
        }
        author {
            name
            avatar {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 100, height: 100, placeholder: NONE, layout: FIXED)
                    }
                }
            }
        }
        seo {
            ...Seo
        }
        ctaButton {
            ...CTAButton
        }
    }
    serviceBanners: allStrapiServiceV3(
        filter: {slug: {in: $serviceBannersSlugs}, locale: {eq: $locale}}
    ) @include(if: $hasServiceBanners) {
        nodes {
            name
            slug
            bannerText
            bannerImage {
                localFile {
                    url
                    childImageSharp {
                        gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
                    }
                }
            }
        }
    }
    featured: allStrapiBlogPost(
        filter: {isFeatured: {eq: true}, language: {eq: $lang}}
        limit: 3
    ) {
        edges {
            node {
                title
                slug
                isFeatured
                category
                createdAt
                language
                author {
                    name
                }
                intro {
                    data {
                        childMarkdownRemark {
                            html
                        }    
                    }
                }
                image {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 150, height: 135, placeholder: NONE, layout: FIXED)
                        }
                    }
                }
            }
        }
    }
}
`;

export default function BlogPost({data, pageContext}) {
    const intl = useIntl();
    useEffect(() => {
        initSpreaker();
    }, []);

    const currentUrl = useCurrentUrl();
    const urlToPath = useUrlToPath();

    const image = data.post.seo?.image?.localFile?.childImageSharp?.gatsbyImageData.src
    const seo = createSeoData(data.post.seo, data.post.updatedAt, data.post.createdAt);

    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   enableRecaptchaBadge={true}
                   title={data.post.title}
                   seo={seo}
                   lang={pageContext.lang}
                   defaultHrefLang={pageContext.lang}
                   hrefLangs={[pageContext.lang]}>

        <ArticleJsonLd
            url={currentUrl}
            headline={data.post.title}
            description={data.post.intro.data.childMarkdownRemark.excerpt}
            overrides={{
                '@type': 'BlogPosting',
            }}
            images={image ? [urlToPath(image)] : []}
            publisherName="GGS IT Consulting"
            authorName={data.post.author.name}
            authorType="Person"
            datePublished={dateTimeToDate(seo.publishDate)}
            dateModified={dateTimeToDate(seo.modifiedDate)}
            publisherLogo={urlToPath('logo_light.svg')}
        />

        <PageMainColumnWrapper>
            <div className={styles.mainContentWrapper}>
                <div className={styles.mainContent}>
                    <Breadcrumbs className={styles.breadcrumbs}>
                        <Breadcrumbs.Item to="/blog">{intl.formatMessage({id: 'menu.blog'})}</Breadcrumbs.Item>
                        <Breadcrumbs.Item>{data.post.title}</Breadcrumbs.Item>
                    </Breadcrumbs>
                    <BlogPostHeader
                        title={data.post.title}
                        publishDate={data.post.publishDate}
                        author={{
                            name: data.post.author.name,
                            avatar: data.post.author.avatar.localFile.childImageSharp
                        }}
                        intro={data.post.intro.data}
                        image={data.post.image.localFile}
                    />
                    <BlogPostContent className={styles.blogContent} content={[data.post.content.data]}
                                     ctaButton={data.post.ctaButton}/>
                </div>
                <div className={styles.aside}>
                    <div className={styles.stickyWrapper}>
                        <div className={styles.stickyPart}>
                            {data.serviceBanners && data.serviceBanners.nodes.map((x, i) => {
                                return <ServiceBanner title={x.name}
                                                      text={x.bannerText}
                                                      key={i}
                                                      slug={x.slug}
                                                      image={x.bannerImage?.localFile}/>
                            })}
                        </div>
                    </div>

                </div>
            </div>

        </PageMainColumnWrapper>
    </Layout>
}
